<template>
<div class="container">
    <div class="text-justify">
        <h2>CONSULTORÍA Y LITIGIO FISCAL</h2>
        <p>
            Dentro del área de derecho fiscal, nuestro equipo especializado ofrece una amplia gama de servicios, entre ellos, la interposición de los medios de defensa que resulten procedentes en contra de todos aquellos actos que emanen de las Autoridades fiscales, el acompañamiento a lo largo de los procedimientos administrativos instaurados por las Autoridades fiscales con motivo de sus facultades de comprobación, la tramitación de diversas solicitudes y/o procedimientos administrativos en general, elaboración de opiniones jurídicas respecto a planteamientos complejos y novedosos en materia fiscal, entre otros.
            <br>
            <br>
            Dentro del área de derecho fiscal, nuestro equipo especializado ofrece una amplia gama de servicios, entre ellos, la interposición de los medios de defensa que resulten procedentes en contra de todos aquellos actos que emanen de las Autoridades fiscales, el acompañamiento a lo largo de los procedimientos administrativos instaurados por las Autoridades fiscales con motivo de sus facultades de comprobación, la tramitación de diversas solicitudes y/o procedimientos administrativos en general, elaboración de opiniones jurídicas respecto a planteamientos complejos y novedosos en materia fiscal, entre otros.
        </p>
        <h3>ASESORÍA FISCAL</h3>
        <p>
            <ul class="asesoria">
                <li><span class="font-regular">Asesoría en el diseño e implementación de proyectos fiscales-financieros acordes a las operaciones
                        comerciales.</span>
                </li>
                <li> <span class="font-regular">Asesoría, consultoría y elaboración de opiniones jurídicas en materia fiscal.
                    </span></li>
                <li> <span class="font-regular">Asesoría a efecto de atender las cartas invitación emitidas por las Autoridades fiscales.
                    </span></li>
                <li> <span class="font-regular">Asesoría en elaboración de solicitudes de devolución de impuestos.
                    </span></li>
                <li> <span class="font-regular">Asesoría jurídica en elaboración de diversas solicitudes y trámites ante las Autoridades fiscales.
                    </span></li>
                <li> <span class="font-regular">Consultoría en la aplicación de los diversos beneficios de los decretos o programas de beneficios fiscales.
                    </span></li>
            </ul>
        </p>
        <h3>DEFENSA LEGAL EN MATERIA TRIBUTARIA</h3>
        <p>
            <ul class="asesoria">
                <li><span class="font-regular">Defensa legal en contra de sanciones, multas, créditos fiscales o resoluciones que causen perjuicio a los intereses del particular, que hayan sido emitidos por las Autoridades fiscales Municipales, Estatales y Federales.</span></li>
                <li> <span class="font-regular">Defensa legal en contra de las resoluciones en sentido negativo respecto de las solicitudes de devolución de impuestos.</span></li>
                <li> <span class="font-regular">Tramitación de amparos en contra de leyes fiscales y actos emanadas de éstas, que causen perjuicio a los intereses de los particulares.</span></li>
                <li> <span class="font-regular">Asesoría en elaboración de solicitudes de devolución de impuestos.</span></li>
                <li> <span class="font-regular">Acompañamiento y asesoría legal durante las facultades de comprobación por parte de las Autoridades fiscales.</span></li>
                <li> <span class="font-regular">Acompañamiento y asesoría legal durante las facultades de comprobación que se den con motivo del artículo 69-B del Código Fiscal de la Federación.</span></li>
            </ul>
        </p>

    </div>
</div>
</template>

<script>
export default {
    name: "Area1"
}
</script>

<style scoped>
ul.asesoria {
    list-style-type: upper-alpha;
    font-family: "Lato Bold";
}

.font-regular {
    font-family: "Lato Regular";
}
</style>
