var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-bottom no-top",attrs:{"id":"content"}},[_c('div',{attrs:{"id":"top"}}),_vm._m(0),_c('section',{staticStyle:{"padding-top":"60px"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row d-flex justify-content-center"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"p-1 pt-2 msg-container"},[_c('form',{staticClass:"form-border",attrs:{"name":"contactForm","id":"contact_form","method":"post","action":_vm.formUrl}},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"spacer-half"}),_vm._m(7),_c('div',{staticClass:"success",attrs:{"id":"mail_success"}},[_vm._v("Tu mensaje se envió correctamente")]),_c('div',{staticClass:"error",attrs:{"id":"mail_fail"}},[_vm._v("Hubo un error al enviar tu mensaje")]),_vm._m(8)])])])]),_c('div',{staticClass:"row mt-5"},[_vm._m(9),_c('google-map',{staticClass:"gmap",attrs:{"center":{lat:28.658569825223477, lng:-106.12955500789289},"fullsm":true}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-light",staticStyle:{"background-image":"url('images/background/contact.png')","background-size":"cover"},attrs:{"id":"subheader","data-stellar-background-ratio":".2"}},[_c('div',{staticClass:"center-y relative text-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center"},[_c('h1',[_vm._v("Contacto")]),_c('p',[_vm._v("Conócenos")])]),_c('div',{staticClass:"clearfix"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',{staticStyle:{"margin-bottom":"0","font-size":"2em"}},[_vm._v("¿Quieres enviarnos un mensaje?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row d-flex justify-content-center pt-2"},[_c('div',{staticClass:"col-12 col-lg-12",staticStyle:{"overflow":"hidden"}},[_c('img',{staticStyle:{"width":"100%","border":"4px solid rgb(16, 16, 81)"},attrs:{"src":"images/background/contactside.jpg","alt":"Hombre firmando documentos"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-set"},[_c('input',{staticClass:"form-control csm-input",attrs:{"type":"text","name":"name","id":"name","placeholder":"Tu nombre"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-set"},[_c('input',{staticClass:"form-control csm-input",attrs:{"type":"text","name":"email","id":"email","placeholder":"Tu email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-set"},[_c('input',{staticClass:"form-control csm-input",attrs:{"type":"text","name":"phone","id":"phone","placeholder":"Tu teléfono"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-set"},[_c('textarea',{staticClass:"form-control csm-input",attrs:{"name":"message","id":"message","placeholder":"Tu mensaje"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row d-flex justify-content-end",staticStyle:{"margin":"0"},attrs:{"id":"submit"}},[_c('input',{staticClass:"btn text-light bg-color-blue btn-smaller",attrs:{"type":"submit","id":"send_message","value":"Enviar"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt2-mob"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('span',[_c('i',{staticClass:"id-color fa fa-map-marker fa-lg"}),_vm._v("   Edificio G3, oficina O-02, primer piso, Ave. Periférico de la Juventud #5955, Col. Saucito., C.P. 31110")])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',[_c('span',[_c('i',{staticClass:"id-color fa fa-phone fa-lg"}),_vm._v("   614 443 1518")]),_c('br'),_c('span',[_c('i',{staticClass:"id-color fa fa-envelope-o fa-lg"}),_vm._v("   "),_c('a',{attrs:{"href":"mailto:contacto@aurumcorp.com.mx"}},[_vm._v("contacto@aurumcorp.com.mx")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',{staticStyle:{"margin-bottom":"0","font-size":"2em"}},[_vm._v("Visita nuestra ubicación")])])
}]

export { render, staticRenderFns }