<template>
          <div id="topbar" class="topbar-noborder">
            <div class="container">
                <div class="topbar-left sm-hide">
                    <span class="topbar-widget tb-social">
                        <a href="https://www.facebook.com/Aurum-Corporativo-Legal-103115064711077"><i class="fa fa-facebook"></i></a>
                        <a href="https://www.instagram.com/aurumcorporativolegal/?hl=es-la"><i class="fa fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/aurum-corporativo-legal/"><i class="fa fa-linkedin"></i></a>
                    </span>
                </div>
                <div class="topbar-right">
                    <div class="topbar-right">

                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
</template>

<script>
export default {
    name: "TopBar"
}
</script>

<style>
#topbar a i {
    color: white;
}

#topbar a i:hover {
    color: #abb2ca;
}
</style>