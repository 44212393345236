<template>
    <div class="card blog-card">
        <img :src="blog.imgUrl" width="100%">
        <h2 class="title mt-4 mb-3">{{blog.title}}</h2>
        <div class="row d-flex justify-content-end" style="margin: 0;">
            <a :href="blog.url" class="btn-smaller text-light bg-color-blue">Leer</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogCard',
    props: {
        blog: {
            type: Object,
            required: true
        },
    },
}
</script>

<style>
.blog-card {
    border: transparent !important;
}

.title {
    color: var(--primary-color) !important;
}

.btn-smaller {
    padding: .5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-family: "Lato Bold";
    border-radius: .3em;
}
</style>