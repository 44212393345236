<template>
<!-- content begin -->
<div class="no-bottom no-top" id="content">
    <div id="top"></div>
    <!-- section begin -->
    <section id="subheader" class="text-light" data-stellar-background-ratio=".2" style="background-image: url('images/background/contact.png'); background-size: cover;">
        <div class="center-y relative text-center">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Contacto</h1>
                        <p>Conócenos</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </section>

    <section style="padding-top: 60px;">
        <div class="container">
            <!--
            <div class="row d-flex justify-content-center contact-text">
                <div class="col-12 col-lg-8">
                    <p class="text-justify">
                        Nuestro equipo está listo para atender sus necesidades y brindarle soluciones legales efectivas y confiables para su empresa.
                    </p>
                </div>
            </div>
            -->
            <div class="row d-flex justify-content-center">
                <div class="col-12">
                    <h2 style="margin-bottom: 0; font-size: 2em;">¿Quieres enviarnos un mensaje?</h2>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="row d-flex justify-content-center pt-2">
                        <div class="col-12 col-lg-12" style="overflow: hidden">
                            <img src="images/background/contactside.jpg" alt="Hombre firmando documentos" style="width: 100%; border: 4px solid rgb(16, 16, 81);" />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="p-1 pt-2 msg-container">
                        <form name="contactForm" id="contact_form" class="form-border" method="post" :action="formUrl">
                            <div class="field-set">
                                <input type="text" name="name" id="name" class="form-control csm-input" placeholder="Tu nombre" />
                            </div>
                            <div class="field-set">
                                <input type="text" name="email" id="email" class="form-control csm-input" placeholder="Tu email" />
                            </div>
                            <div class="field-set">
                                <input type="text" name="phone" id="phone" class="form-control csm-input" placeholder="Tu teléfono" />
                            </div>
                            <div class="field-set">
                                <textarea name="message" id="message" class="form-control csm-input" placeholder="Tu mensaje"></textarea>
                            </div>
                            <div class="spacer-half"></div>
                            <div class="row d-flex justify-content-end" id="submit" style="margin:0;">
                                <input type="submit" id="send_message" value="Enviar" class="btn text-light bg-color-blue btn-smaller" />
                            </div>
                            <div id="mail_success" class="success">Tu mensaje se envió correctamente</div>
                            <div id="mail_fail" class="error">Hubo un error al enviar tu mensaje</div>

                            <div class="row mt2-mob">
                                <div class="col-12 col-lg-6">
                                    <span><i class="id-color fa fa-map-marker fa-lg"></i>&nbsp;&nbsp; Edificio G3, oficina O-02, primer piso, Ave. Periférico de la Juventud #5955, Col. Saucito., C.P. 31110</span>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div>
                                        <span><i class="id-color fa fa-phone fa-lg"></i>&nbsp;&nbsp; 614 443 1518</span>
                                        <br><span><i class="id-color fa fa-envelope-o fa-lg"></i>&nbsp;&nbsp; <a href="mailto:contacto@aurumcorp.com.mx">contacto@aurumcorp.com.mx</a></span>

                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12">
                    <h2 style="margin-bottom: 0; font-size: 2em;">Visita nuestra ubicación</h2>
                </div>
                <google-map class="gmap" :center="{lat:28.658569825223477, lng:-106.12955500789289}" :fullsm="true"></google-map>
            </div>
        </div>
    </section>
    <!-- section close -->

</div>
</template>

<script>
import GoogleMap from "../components/GoogleMap";
export default {
    name: 'Contact',
    components: {
        GoogleMap
    },
    computed: {
        formUrl() {
            return process.env.VUE_APP_FORMSPREE_URL;
        }
    },
}
</script>

<style scoped>
.csm-input {
    margin-bottom: 10px !important;
    border: 1px solid black !important;
    border-radius: 1em !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: black;
}

.btn-smaller {
    padding: .5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-family: "Lato Bold";
    border-radius: .3em;
}

.form-border input[type=text] {
    color: black !important;
}

textarea:focus {
    color: black !important;
}

.map-col {
    overflow: hidden;
}

.contact-text {
    font-size: 1.3em;
}

#contact_form textarea {
    height: 90px;
}

.gmap {
    width: calc(100% - 30px);
}

@media (max-width: 600px) {

    .xl-map-container {
        padding: 0 !important;
    }

    .msg-container {
        padding: 0 !important;
    }

    .mt2-mob {
        margin-top: 2em;
    }

    .gmap {
        margin-bottom: 2em;
    }

    .contact-text {
        font-size: 1.1em;
        margin-bottom: 1em;
    }
}
</style>
