<template>
<div class="no-bottom no-top" id="content">
    <div id="top"></div>
    <!-- section begin -->
    <section id="subheader" class="text-light main-section-background" data-stellar-background-ratio=".2">
        <div class="center-y relative text-center">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <div class="spacer-single"></div>
                        <h1>La firma</h1>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- section close -->
    <section aria-label="section" data-bgcolor="#ffffff" style="background-color: #ffffff">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5">
                    
                    <h2>¿Quiénes somos?</h2>
                    <p class="text-justify">Somos una firma enfocada en la prestación de servicios legales especializados para empresarios. Nuestro principal objetivo es brindar soluciones efectivas, confiables y rentables en favor de nuestros clientes a través de un servicio personalizado y directo, velando en todo momento por el bienestar de su patrimonio.
                       <br><br> Nuestro equipo está integrado por abogados con amplia experiencia profesional y que además se encuentran en constante capacitación, lo que nos permite brindar soluciones integrales a nuestros clientes, así como una orientación jurídica certera para superar los obstáculos y exigencias del mercado a las que se enfrentan día a día.
                    </p>
                </div>
                <div class="col-md-6 offset-md-1">
                    <div class="de-images">
                        <img class="di-small-2" src="images/misc/firma_2.jpg" alt="" />
                        <img class="di-big img-fluid" src="images/misc/firma_1.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>
export default {
    name: "About",
    data() {
        return {

        }
    }
}
</script>

<style scoped>
@media (max-width: 990px) {
    .main-section-background {
        background-image: url('/images/background/new_mobile_firma.png'); 
        background-size: cover;
    }
}
@media (min-width: 991px) {
    .main-section-background {
        background-image: url('/images/background/new_firma.png'); 
        background-size: cover;
    }
}
</style>
