<template>
<!-- content begin -->
<div class="no-bottom no-top" id="content">
    <div id="top"></div>
    <!-- revolution slider begin -->
    <section id="section-slider" class="fullwidthbanner-container text-white" aria-label="section-slider">
        <div id="slider-revolution">
            <ul>
                <li data-transition="fade" data-slotamount="10" data-masterspeed="300" data-thumb="">
                    <!--  BACKGROUND IMAGE -->
                    <img alt="" class="rev-slidebg" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" src="images/background/home1.png">
                    <div class="tp-caption big-s1" data-x="0" data-y="230" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_in="y:100px;opacity:0;s:500;e:Power2.easeOut;" data-transform_out="opacity:0;y:-100;s:200;e:Power2.easeInOut;" data-start="500" data-splitin="none" data-splitout="none" data-responsive_offset="on">
                        <!--                                <h3 class="id-color">Lorem</h3>-->
                    </div>
                    <div class="tp-caption very-big-white" data-x="0" data-y="280" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_in="y:100px;opacity:0;s:500;e:Power2.easeOut;" data-transform_out="opacity:0;y:-100;s:400;e:Power2.easeInOut;" data-start="600" data-splitin="none" data-splitout="none" data-responsive_offset="on">
                        <h1 class="title-home">Los clientes, nuestra prioridad;</h1>
                        <h1 class="title-home th-2">los resultados efectivos, nuestro motor.</h1>
                    </div>
                    <div class="tp-caption" data-x="0" data-y="360" data-width="480" data-height="none" data-whitespace="wrap" data-transform_in="y:100px;opacity:0;s:500;e:Power2.easeOut;" data-transform_out="opacity:0;y:-100;s:600;e:Power2.easeInOut;" data-start="700">
                        <!--                                <p class="lead xs-hide">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A asperiores consequatur deserunt eos error fuga illo impedit in magni mollitia nihil provident quibusdam quidem quis repellendus similique suscipit, totam ut.</p>-->
                    </div>
                    <!---
                        <div class="tp-caption bg-sc" data-x="0" data-y="450" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_in="y:100px;opacity:0;s:500;e:Power2.easeOut;" data-transform_out="opacity:0;y:-100;s:800;e:Power2.easeInOut;" data-start="800">
                            <a class="btn-custom primary_color_bg" @click="toContact()">Contactános</a>
                        </div>
                        -->
                </li>
                <li data-transition="fade" data-slotamount="10" data-masterspeed="300" data-thumb="">
                    <!--  BACKGROUND IMAGE -->
                    <img alt="" class="rev-slidebg" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" src="images/background/home2.png">
                    <div class="tp-caption big-s1" data-x="0" data-y="230" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_in="y:100px;opacity:0;s:500;e:Power2.easeOut;" data-transform_out="opacity:0;y:-100;s:200;e:Power2.easeInOut;" data-start="500" data-splitin="none" data-splitout="none" data-responsive_offset="on">
                        <!--                                <h3 class="id-color">Law &amp; Insurance Specialist</h3>-->
                    </div>
                    <div class="tp-caption very-big-white" data-x="0" data-y="280" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_in="y:100px;opacity:0;s:500;e:Power2.easeOut;" data-transform_out="opacity:0;y:-100;s:400;e:Power2.easeInOut;" data-start="600" data-splitin="none" data-splitout="none" data-responsive_offset="on">
                        <h1 class="title-home">Construyendo soluciones</h1>
                        <h1 class="title-home th-2">innovadoras y efectivas</h1>
                    </div>
                    <div class="tp-caption" data-x="0" data-y="360" data-width="480" data-height="none" data-whitespace="wrap" data-transform_in="y:100px;opacity:0;s:500;e:Power2.easeOut;" data-transform_out="opacity:0;y:-100;s:600;e:Power2.easeInOut;" data-start="700">
                        <!--                                <p class="lead xs-hide">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
                    </div>
                    <!---
                        <div class="tp-caption bg-sc" data-x="0" data-y="450" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_in="y:100px;opacity:0;s:500;e:Power2.easeOut;" data-transform_out="opacity:0;y:-100;s:800;e:Power2.easeInOut;" data-start="800">
                            <a class="btn-custom primary_color_bg" @click="toContact()">Contactános</a>
                        </div>
                        -->
                </li>
            </ul>
        </div>
    </section>
    <!-- revolution slider close -->
    <section class="pt40 pb40 primary_color_bg text-light">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-8 mb-sm-30 text-lg-left text-sm-center">
                    <h3 class="no-bottom">¿Quieres hacer una cita?</h3>
                </div>
                <div class="col-md-4 text-lg-right rtl-lg-left text-sm-center">
                    <a @click="toContact()" class="btn-custom btn-white text-dark">Haz una cita</a>
                </div>
            </div>
        </div>
    </section>
    <!-- revolution slider close -->
    <section id="section-highlight" class="relative text-light grey_color_bg pt20 pb20" data-bgcolor="#1B1B1B">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <!--                        <span class="p-title">Welcome</span><br>-->
                    <h2>
                        Soluciones legales efectivas para empresarios.
                    </h2>
                    <div class="small-border sm-left" style="border-color: white"></div>
                </div>
                <div class="col-md-12">
                    <p>En Aurum somos conscientes que las empresas hoy en día necesitan contar con una asesoría legal especializada de un equipo que además de pensar como abogados, piensen como empresarios.</p>
                </div>
            </div>
            <div class="spacer-10"></div>
        </div>
        <div class="spacer-10"></div>
    </section>
    <!-- la firma -->
    <section id="section-highlight" class="relative pt20 pb20">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <!--                        <span class="p-title">Welcome</span><br>-->
                    <h2>
                        La firma
                    </h2>
                    <div class="small-border sm-left" style="border-color: black"></div>
                </div>
                <div class="col-md-12">
                    <p class="text-justify">Somos una firma enfocada en la prestación de servicios legales
                        especializados para empresarios. Nuestro principal objetivo es brindar soluciones efectivas,
                        confiables y rentables en favor de nuestros clientes a través de un servicio personalizado y
                        directo, velando en todo momento por el bienestar de su patrimonio.
                        <br><br> Nuestro equipo está integrado por abogados con amplia experiencia profesional y que además
                        se encuentran en constante capacitación, lo que nos permite brindar soluciones integrales a
                        nuestros clientes, así como una orientación jurídica certera para superar los obstáculos y
                        exigencias del mercado a las que se enfrentan día a día.
                    </p>
                </div>
            </div>
            <div class="spacer-10"></div>
        </div>
    </section>
    <!-- practice areas -->
    <section class="no-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-left">
                        <h2>Áreas de práctica</h2>
                        <div class="small-border sm-left"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30">
                    <div class="feature-box f-boxed style-3 text-center" @click="toArea('1')">
                        <i class="id-color icofont-law-alt-3"></i>
                        <div class="text max-size">
                            <h4>CONSULTORÍA Y LITIGIO FISCAL</h4>
                        </div>
                        <i class="wm icofont-law-alt-3"></i>
                        <div class="spacer-single"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30">
                    <div class="feature-box f-boxed style-3 text-center" @click="toArea('2')">
                        <i class="id-color icofont-law-alt-3"></i>
                        <div class="text max-size">
                            <h4>CONSULTORÍA Y LITIGIO ADMINISTRATIVO</h4>
                        </div>
                        <i class="wm icofont-law-alt-3"></i>
                        <div class="spacer-single"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30">
                    <div class="feature-box f-boxed style-3 text-center" @click="toArea('3')">
                        <i class="id-color icofont-bank-alt"></i>
                        <div class="text max-size">
                            <h4>DERECHO Y GOBIERNO CORPORATIVO</h4>
                        </div>
                        <i class="wm icofont-bank-alt"></i>
                        <div class="spacer-single"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="feature-box f-boxed style-3 text-center" @click="toArea('4')">
                        <i class="id-color icofont-handshake-deal"></i>
                        <div class="text max-size">
                            <h4>DERECHO EN COMERCIO EXTERIOR</h4>
                        </div>
                        <i class="wm icofont-handshake-deal"></i>
                        <div class="spacer-single"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30">
                    <div class="feature-box f-boxed style-3 text-center" @click="toArea('5')">
                        <i class="id-color icofont-law-order"></i>
                        <div class="text max-size">
                            <h4>LEY FEDERAL PARA LA PREVENCIÓN E IDENTIFICACIÓN </h4>
                        </div>
                        <i class="wm icofont-law-order"></i>
                        <div class="spacer-single"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="feature-box f-boxed style-3 text-center" @click="toArea('6')">
                        <i class="id-color icofont-businesswoman"></i>
                        <div class="text max-size">
                            <h4>DERECHO EN SEGURIDAD SOCIAL</h4>
                        </div>
                        <i class="wm icofont-businesswoman"></i>
                        <div class="spacer-single"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="feature-box f-boxed style-3 text-center" @click="toArea('7')">
                        <i class="id-color icofont-company"></i>
                        <div class="text max-size">
                            <h4>CORPORATE COMPLIANCE</h4>
                        </div>
                        <i class="wm icofont-company"></i>
                        <div class="spacer-single"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="feature-box f-boxed style-3 text-center" @click="toArea('8')">
                        <i class="id-color icofont-presentation-alt"></i>
                        <div class="text max-size">
                            <h4>DUE DILLIGENCE</h4>
                        </div>
                        <i class="wm icofont-presentation-alt"></i>
                        <div class="spacer-single"></div>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section data-bgcolor="#1B1B1B" class="text-light" style="background-size: cover; background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url('images/background/valores.jpg');">
        <div class="container values-container">

            <div class="row justify-content-center mb-5">
                <h2>
                    Principios que nos distinguen
                </h2>
            </div>

            <div class="row">
                <div class="col-12 col-lg-4">
                    <div class="hover-box d-flex align-items-center justify-content-center p-2" :class="{'bg-white': showMission}" @mouseover="showMission = true" @mouseleave="showMission = false">
                        <div v-show="!showMission" class="hover-box-title">Misión</div>
                        <div v-show="showMission" class="p-2">
                            <span class="hover-box-title">Misión</span>
                            <br>
                            <br>
                            Ofrecer a nuestros clientes un servicio legal directo y especializado que brinde resultados efectivos y confiables para sus empresas, a través soluciones innovadoras.
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-4">
                    <div class="hover-box d-flex align-items-center justify-content-center p-2" :class="{'bg-white': showVision}" @mouseover="showVision = true" @mouseleave="showVision = false">
                        <div v-show="!showVision" class="hover-box-title">Visión</div>
                        <div v-show="showVision" class="p-2">
                            <span class="hover-box-title">Visión</span>
                            <br>
                            <br>
                            Ser una Firma reconocida a nivel Internacional por su alto estándar de calidad en la prestación de servicios legales, así como por la capacidad de brindar soluciones eficaces e innovadoras, y como consuencia de ello, ser considerados por cada uno de nuestros clientes como un socio estratégico.
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-4">
                    <div class="hover-box d-flex align-items-center justify-content-center p-2" :class="{'bg-white': showValues}" @mouseover="showValues = true" @mouseleave="showValues = false">
                        <div v-show="!showValues" class="hover-box-title">Valores</div>
                        <div v-show="showValues" class="p-2">
                            <span class="hover-box-title">Valores</span>
                            <br>
                            <br>
                            Sabemos que el éxito en los resultados que brindamos a nuestros clientes proviene de
                            una debida práctica profesional, es por ello que
                            nuestros asesores desarrollan sus servicios dirigiéndose bajo los siguientes
                            principios rectores:
                            <div class="row">
                                <div class="col">

                                    ● Compromiso
                                    <br> ● Eficiencia

                                </div>
                                <div class="col">
                                    ● Lealtad
                                    <br> ● Innovación
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>

</div>
<!-- content close -->
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            showValues: false,
            showVision: false,
            showMission: false,
        }
    },

    methods: {
        toContact() {
            this.$router.push('contact')
        },
        toArea(area) {
            console.log("clicking")
            let route = 'Area' + area;
            this.$router.push({
                name: route
            })
        },
        toSpecificArea(id) {
            this.$router.push({
                path: `/areas/${id}`
            })
        },
        initSlider() {
            jQuery(document).ready(function () {
                // revolution slider
                jQuery("#slider-revolution").revolution({
                    sliderType: "standard",
                    sliderLayout: "fullwidth",
                    delay: 5000,
                    navigation: {
                        arrows: {
                            enable: false
                        },
                        bullets: {
                            enable: true,
                            hide_onmobile: false,
                            style: "hermes",
                            hide_onleave: false,
                            direction: "horizontal",
                            h_align: "center",
                            v_align: "bottom",
                            h_offset: 20,
                            v_offset: 30,
                            space: 5,
                        },

                    },
                    parallax: {
                        type: "mouse",
                        origo: "slidercenter",
                        speed: 2000,
                        levels: [2, 3, 4, 5, 6, 7, 12, 16, 10, 50],
                    },
                    responsiveLevels: [1920, 1380, 1240],
                    gridwidth: [1200, 1200, 940],
                    spinner: "off",
                    gridheight: 700,
                    disableProgressBar: "on"
                });
            });
        }
    },
    mounted() {
        this.initSlider();
    }
}
</script>

<style scoped>
.primary_color_bg {
    background-color: rgb(16, 16, 81) !important;
}

.grey_color_bg {
    background-color: rgb(27, 27, 27) !important;
}

.primary_color {
    color: rgb(16, 16, 81) !important;
}

.grey_color {
    color: rgb(27, 27, 27) !important;
}

.max-size {
    max-height: 300px;
    overflow: hidden;
}

.values-container {
    min-height: 450px;
    max-height: auto;
}

.feature-col {
    min-height: 10em;
}

.feature-box {
    min-height: 17em;
}

.f-box.f-icon-left {
    align-items: center;
}

.f-box.f-icon-rounded i:hover {
    width: 100px;
    height: 100px;
}

.f-box i:hover {
    font-size: 50px;
}

.f-box:hover {
    cursor: pointer;
}

.feature-box {
    cursor: pointer;
}

.hover-box {
    width: 100%;
    height: 350px;
    background-color: black;
    font-size: 1em;
    text-align: center;
    margin-bottom: 2em;
    -webkit-transition: all 0.6s linear;
    transition: all 0.6s linear;
}



.hover-box-title {
    font-size: 1.7em;
    font-family: "Lato Bold";
    text-decoration: underline;
    text-underline-offset: .1em;
}

.bg-white {
    background-color: white;
    color: black;
}



@media (max-width: 991px) {
    .title-home {
        font-size: .8em;
    }

    .values-container {
        min-height: auto;
        height: auto;
    }

    .feature-box {
        min-height: auto;
    }

    .bg-sc {
        display: none !important;
    }

    .th-2 {
        margin-top: -2em;
    }
}
</style>
