import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as VueGoogleMaps from "vue2-google-maps";
import { process_params } from 'express/lib/router'
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDFQLSKl-bB4wmXbwzo9Sdzepr2oMvv8Lc",
    libraries: "places" // necessary for places input
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
