<template>
<div class="container text-justify">
    <div>
        <p>
            En Aurum Corporativo Legal la prioridad son nuestros clientes, es por
            ello que buscamos siempre brindarles soluciones innovadoras que atiendan
            de forma directa y efectiva sus necesidades.
            <br /><br />
            En ese sentido, brindamos una asesoría personalizada, con la finalidad
            de conocer sus principales inquietudes y necesidades, al igual que la
            esencia y características de cada negocio, con la intención de adecuar y
            diseñar nuestros servicios legales para cada caso específico. Bajo esta
            premisa, nuestro equipo brinda a nuestros clientes servicios
            especializados en las siguientes materias del derecho:
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: "AreaIntro",
};
</script>

<style scoped>
</style>
