<template>
<div class="container text-justify">
    <div>
        <h2>CORPORATE COMPLIANCE</h2>
        <p> El entorno jurídico imperante en la actualidad exige que las empresas cuenten con un asesoramiento jurídico
            óptimo que les permita evaluar y minimizar cualquier riesgo que se pueda generar frente a la empresa misma, sus
            accionistas y funcionarios, no solo en cuestión de negociaciones y materialización de transacciones comerciales,
            sino también respecto a todas las actividades que se desarrollan diariamente.

        </p>
        <p>Atendiendo a lo anterior, nuestro equipo especializado en materia de Auditoria Legal brinda el servicio de
            evaluar el debido funcionamiento de los órganos de administración de la empresa, así como el cumplimiento de
            todas y cada una de las disposiciones normativas y autorizaciones necesarias para el desarrollo de sus
            operaciones comerciales diarias relacionadas con las siguientes áreas del derecho:</p>
        <ul style="list-style-type: upper-alpha;">
            <li> Compliance en Impuestos Internos.

            </li>
            <li>Compliance en Comercio Exterior.

            </li>
            <li>Compliance en Seguridad Social.
            </li>
            <li>Compliance en Permisos, Autorizaciones y Concesiones otorgadas por el Estado.

            </li>
            <li>Compliance en Salubridad e Higiene.
            </li>
            <li>Compliance en materia Ambiental.
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    name: "Area7"
}
</script>

<style scoped>
</style>
