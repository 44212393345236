<template>
  <div class="container">
    <div class="text-justify">
      <h2>DERECHO EN SEGURIDAD SOCIAL</h2>
      <p> En Aurum Corporativo Legal comprendemos que el mayor activo de una compañía es su fuerza laboral, es por ello
        que,
        nuestro equipo especializado acompaña a nuestros clientes en la implementación de acciones legales y brinda
        asesoría jurídica respecto a la responsabilidad que les corresponde bajo su calidad de patrones, mediante la
        defensa legal de cualquier responsabilidad fiscal que los diversos organismos en materia de seguridad social
        puedan llegar a fincar, en su caso.
      </p>
      <p>Así mismo, nuestro equipo legal asesora a nuestros clientes en el diseño e implementación de estructuras de
        otorgamiento de prestaciones sociales, con el objetivo de ofrecer los mejores beneficios a los trabajadores,
        velando siempre por la condición fiscal-financiera de su empresa.</p>

      <h3>
        PLANEACIÓN FISCAL EN EL RAMO DE SEGURIDAD SOCIAL Y TRÁMITES ADMINISTRATIVOS

      </h3>
      <ul style="list-style-type: upper-alpha;">
        <li>Asesoría y trámite de Sustitución Patronal y Fusión de Sociedades frente a los diversos Institutos de
          Seguridad
          Social.
        </li>
        <li>Elaboración de Planes de Previsión Social para salvaguardar lo intereses de sus trabajadores y la correcta
          deducción fiscal de las prestaciones otorgadas por la empresa.
        </li>
        <li>Elaboración de Reglamentos de Fondo de Ahorro y del órgano de Administración del referido Fondo.</li>
        <li>Asesoramiento y diligencia de trámites frente a los diversos Institutos de Seguridad Social (IMSS,
          INFONAVIT,
          FONACOT, ISSSTE, etc.).
        </li>
        <li>Asesoría en el control interno del otorgamiento de prestaciones de seguridad social a los trabajadores.</li>
      </ul>

      <h3>DEFENSA LEGAL</h3>
      <ul style="list-style-type: upper-alpha;">
        <li>Asesoría en procedimientos de fiscalización iniciados por las Autoridades Administrativas en Materia de
          Seguridad Social.
        </li>
        <li>Interposición de Recursos Administrativos ante los Institutos en materia de Seguridad Social.
        </li>
        <li>Tramitación de juicios ante los Tribunales Administrativos.
        </li>
        <li>Tramitación de juicios de amparo contra leyes y actos de las Autoridades en materias de Seguridad Social.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Area6"
}
</script>

<style scoped>
</style>