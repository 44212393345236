<template>
  <div class="container text-justify">
    <div>
      <h2>CONSULTORÍA Y LITIGIO ADMINISTRATIVO</h2>
      <p>
        El desarrollo y creación de nuevas ramas y especializaciones dentro del mercado comercial han llevado al Estado a crear múltiples Leyes, Reglamentos y Disposiciones Legales con el objeto de perfeccionar la regulación de las operaciones de entes económicos, dando como resultado la imposición de mayores cargas a las empresas para la correcta ejecución de su giro comercial.
      </p>
      
      <p>Bajo esa premisa, nuestro equipo especializado busca facilitar las herramientas jurídicas necesarias a nuestros clientes a efecto de que el desarrollo de sus actividades comerciales se encuentre dentro de lo que dicta el marco jurídico aplicable, logrando con ello: 1) Mantener el desarrollo de las operaciones comerciales dentro del marco de la ley; y 2) evitar que se obstaculice la operación económica de su empresa.
      </p>

      <h3>TRÁMITES ADMINISTRATIVOS </h3>
      <p>
        <ul style="list-style-type: upper-alpha;">
          <li><span class="bold">LICITACIONES PÚBLICAS.</span> Brindamos asesoría jurídica especializada en materia de contrataciones con el Sector Público, ya sea a nivel Municipal, Local o Federal. Dicha asesoría se enfoca principalmente al cumplimiento de los requisitos legales y administrativos que se deben cumplir conforme a las bases de cada Licitación. </li>
          <li>Asesoría y trámite en la obtención de permisos, autorizaciones y/o concesiones otorgados por el Sector Público. </li>
          <li>Diseño y elaboración de contratos de prestación de servicios, arrendamientos y adquisiciones con el Sector Público. </li>
        </ul>
      </p>
      <h3>CONSULTORÍA EN LA APLICACIÓN DEL DERECHO ADMINISTRATIVO EN LAS OPERACIONES ECONÓMICAS DE NUESTROS CLIENTES.</h3>
      <p>
        <ul style="list-style-type: upper-alpha;">
          <li>Opiniones Jurídicas, las cuales se desarrollan desde un estudio analítico e integral de las operaciones económicas de nuestros clientes hasta su condición corporativa, amalgamándolos con la aplicación de la norma administrativa aplicable.</li>
          <li>Asesoramiento legal respecto de las decisiones cotidianas de las empresas a efecto de que se desplieguen de la forma que mayor favorezca sus intereses y tutelando el cumplimiento de la norma.</li>
          <li>DEFENSA LEGAL. Asesoría, diseño de esquemas de defensa y representación ante procedimientos de verificación ejecutados por las Autoridades Administrativas.</li>
          <li>Interposición de Recursos Administrativos. Defensa ante los Tribunales Administrativos en contra de los actos de autoridad realizados por los diversos Órganos de Gobierno a nivel Municipal, Estatal y Federal, tales como:
            <ul>
              <li>Revocación de Permisos, licencias, autorizaciones y concesiones.</li>
              <li>Imposición de Sanciones Administrativas.</li>
              <li>Responsabilidad patrimonial.</li>
            </ul>
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Area2"
}
</script>

<style scoped>
</style>