<template>
  <div id="wrapper">
    <top-bar></top-bar>
    <navbar></navbar>
    <router-view/>
    <a href="#" id="back-to-top"></a>
    <div id="preloader">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
    <bot-footer></bot-footer>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import TopBar from "./components/TopBar";
import BotFooter from "./components/BotFooter";

export default {
  components: {
    Navbar,
    TopBar,
    BotFooter
  }
}
</script>
<style>

@font-face {
    font-family: "Lato Black";
    src: local("Lato-Black"),
    url(./assets/fonts/Lato-Black.ttf) format("truetype");
}

@font-face {
    font-family: "Lato BlackItalic";
    src: local("Lato-BlackItalic"),
    url(./assets/fonts/Lato-BlackItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Lato Bold";
    src: local("Lato-Bold"),
    url(./assets/fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Lato BoldItalic";
    src: local("Lato-BoldItalic"),
    url(./assets/fonts/Lato-BoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Lato Italic";
    src: local("Lato-Italic"),
    url(./assets/fonts/Lato-Italic.ttf) format("truetype");
}

@font-face {
    font-family: "Lato Light";
    src: local("Lato-Light"),
    url(./assets/fonts/Lato-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Lato LightItalic";
    src: local("Lato-LightItalic"),
    url(./assets/fonts/Lato-LightItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Lato Regular";
    src: local("Lato-Regular"),
    url(./assets/fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Lato Thin";
    src: local("Lato-Thin"),
    url(./assets/fonts/Lato-Thin.ttf) format("truetype");
}

@font-face {
    font-family: "Lato ThinItalic";
    src: local("Lato-ThinItalic"),
    url(./assets/fonts/Lato-ThinItalic.ttf) format("truetype");
}



@font-face {
    font-family: "Salvalyn";
    src: local("Salvalyn"),
    url(./assets/fonts/Salvalyn.ttf) format("truetype");
}

body {
  font-family: "Lato Regular";
}

.bold {
  font-family: "Lato Bold";
}

/* bottom line for subheaders */
#subheader h1 {
    border-bottom-color: white;
}


.bg-color-blue {
  background-color: #101051 !important;
}

.bg-color-gray {
  background-color: #2c2c2c !important;
}

.blue {
  color: #101051;
}

a:hover {
    color: #abb2ca !important;
}

/** so the text in home doesn't move under weird circumstances */

.tp-parallax-wrap{
    left: 135px !important; top: 270px !important;
}

@media (max-width: 991px) {
  .tp-parallax-wrap {
    left: 0px !important; top: 96px !important;
  }
}

</style>
