<template>
<div class="no-bottom no-top" id="content">
    <div id="top"></div>
    <!-- section begin -->
    <section id="subheader" class="text-light" data-stellar-background-ratio=".2" style="background-image: url('images/background/blog.png'); background-size: cover;">
        <div class="center-y relative text-center">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Blog</h1>
                        <p>Explora nuestros articulos</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- section close -->
    <!-- section begin -->
    <section aria-label="section">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6" v-for="blog in blogs" :key="blog.name">
                    <blog-card class="blog-card" :blog="blog"></blog-card>
                </div>

                <div class="spacer-single"></div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import BlogCard from "../components/BlogCard";
export default {
    name: 'Blog',
    components: {
        BlogCard
    },
    data() {
        return {
            blogs: [{
                    title: '2020',
                    resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi elementum at augue at cursus. Fusce pretium eros nec elementum auctor. Nulla nisl sapien, dignissim non purus eu, placerat volutpat libero.',
                    imgUrl: 'images/blogs/1.jpg',
                    url: "article?year=2020"
                },
                {
                    title: '2021',
                    resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi elementum at augue at cursus. Fusce pretium eros nec elementum auctor. Nulla nisl sapien, dignissim non purus eu, placerat volutpat libero.',
                    imgUrl: 'images/blogs/2.jpg',
                    url: "article?year=2021"
                },
                {
                    title: '2022',
                    resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi elementum at augue at cursus. Fusce pretium eros nec elementum auctor. Nulla nisl sapien, dignissim non purus eu, placerat volutpat libero.',
                    imgUrl: 'images/blogs/3.jpg',
                    url: "article?year=2022"
                },
            ]
        }
    }
}
</script>

<style scoped>
@media (max-width: 991px) {
    .blog-card {
        margin-bottom: 2em;

    }
}
</style>
