<template>
<div>
    <GmapMap :center="this.center" :zoom="16" class="map-style" :class="{ 'map-style-footer ': footer, 'full-sm': fullsm }">
        <GmapMarker :position="this.center" :clickable="false" />
    </GmapMap>
</div>
</template>

<script>
export default {
    props: [
        "center",
        "footer",
        "fullsm"
    ]
}
</script>

<style scoped>
.map-style {
    width: 100%;
    height: 20vh;
    margin-left: 15px;
    border: 4px solid rgb(16, 16, 81);
}

.map-style-footer {
    width: 350px;
    height: 210px;
    border: 7px solid #666666;
}

@media (max-width: 600px) {

    .map-style-footer {
        width: 90%;
    }
    .full-sm {
        width: 85vw;
        height: 250px;
    }
}
</style>
