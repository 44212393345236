<template>
  <div class="container text-justify">
    <div>
      <h2>DUE DILLIGENCE</h2>
      <p> Previo a la materialización de una transacción comercial, resulta necesario contar con todos los elementos
        necesarios para tomar una decisión informada, analizando todos los posibles escenarios de riesgo y de
        oportunidad.

      </p>
      <p> Es por lo anterior que, nuestro equipo especializado en Auditoría Legal asesora a nuestros clientes previo a
        la
        de una compra o venta de una empresa o entidad económica, llevando a cabo un análisis minucioso de los elementos
        que integran a la entidad objeto de la operación, servicio que se presta desde su estructuración y hasta su
        realización de la siguiente forma:</p>
      <h3>COMPRADOR</h3>
      <ul>
        <li> Búsqueda de un producto que atienda a las necesidades específicas de nuestro cliente;


        </li>
        <li>Análisis profundo de la situación jurídica, financiera, fiscal y comercial de la entidad objeto de la
          operación;


        </li>
        <li>Acompañamiento a lo largo de las mesas de negociación.

        </li>
        <li>Análisis, revisión y elaboración de los instrumentos jurídicos que amparen la materialización de la
          transacción comercial.

        </li>
      </ul>
      <h3>VENDEDOR</h3>
      <ul>
        <li> Análisis profundo de la situación jurídica, financiera, fiscal y comercial que guarda la sociedad.
        </li>
        <li>Elaboración de proyecto ejecutivo de venta.
        </li>
        <li>Acompañamiento a lo largo de las mesas de negociación.
        </li>
        <li>Análisis, revisión y elaboración de los instrumentos jurídicos que amparen la materialización de la
          transacción comercial.
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {
  name: "Area8"
}
</script>

<style scoped>
</style>