var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-bottom no-top",attrs:{"id":"content"}},[_c('div',{attrs:{"id":"top"}}),_vm._m(0),_c('router-view',{staticClass:"mt-5"}),_c('section',{staticClass:"pt-4 mt-5"},[_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6 mb30 p-2",on:{"click":function($event){return _vm.toSpecificArea(1)}}},[_vm._m(2)]),_c('div',{staticClass:"col-lg-4 col-md-6 mb30 p-2",on:{"click":function($event){return _vm.toSpecificArea(2)}}},[_vm._m(3)]),_c('div',{staticClass:"col-lg-4 col-md-6 mb30 p-2",on:{"click":function($event){return _vm.toSpecificArea(3)}}},[_vm._m(4)]),_c('div',{staticClass:"col-lg-4 col-md-6 mb30 p-2",on:{"click":function($event){return _vm.toSpecificArea(4)}}},[_vm._m(5)]),_c('div',{staticClass:"col-lg-4 col-md-6 mb30 p-2",on:{"click":function($event){return _vm.toSpecificArea(5)}}},[_vm._m(6)]),_c('div',{staticClass:"col-lg-4 col-md-6 mb30 p-2",on:{"click":function($event){return _vm.toSpecificArea(6)}}},[_vm._m(7)]),_c('div',{staticClass:"col-lg-4 col-md-6 mb30 p-2",on:{"click":function($event){return _vm.toSpecificArea(7)}}},[_vm._m(8)]),_c('div',{staticClass:"col-lg-4 col-md-6 mb30 p-2",on:{"click":function($event){return _vm.toSpecificArea(8)}}},[_vm._m(9)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-light",staticStyle:{"background-image":"url('../images/background/areas.jpg')","background-size":"cover"},attrs:{"id":"subheader","data-stellar-background-ratio":".2"}},[_c('div',{staticClass:"center-y relative text-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('div',{staticClass:"spacer-single"}),_c('h1',[_vm._v("Áreas de práctica")])]),_c('div',{staticClass:"clearfix"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('blockquote',[_vm._v("Lee más acerca de nuestros servicios ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow"},[_c('div',{staticClass:"f-box f-icon-left f-icon-rounded"},[_c('i',{staticClass:"icofont-law-alt-3 bg-color text-light"}),_c('div',{staticClass:"fb-text"},[_c('h4',[_vm._v("CONSULTORÍA Y LITIGIO FISCAL")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow"},[_c('div',{staticClass:"f-box f-icon-left f-icon-rounded"},[_c('i',{staticClass:"icofont-law-alt-3 bg-color text-light"}),_c('div',{staticClass:"fb-text"},[_c('h4',[_vm._v("CONSULTORÍA Y LITIGIO ADMINISTRATIVO")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow"},[_c('div',{staticClass:"f-box f-icon-left f-icon-rounded"},[_c('i',{staticClass:"icofont-bank-alt bg-color text-light"}),_c('div',{staticClass:"fb-text"},[_c('h4',[_vm._v("DERECHO Y GOBIERNO CORPORATIVO")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow"},[_c('div',{staticClass:"f-box f-icon-left f-icon-rounded"},[_c('i',{staticClass:"icofont-handshake-deal bg-color text-light"}),_c('div',{staticClass:"fb-text"},[_c('h4',[_vm._v("DERECHO EN COMERCIO EXTERIOR")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow"},[_c('div',{staticClass:"f-box f-icon-left f-icon-rounded"},[_c('i',{staticClass:"icofont-law-order bg-color text-light"}),_c('div',{staticClass:"fb-text"},[_c('h4',[_vm._v("LEY FEDERAL PARA LA PREVENCIÓN E IDENTIFICACIÓN ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow"},[_c('div',{staticClass:"f-box f-icon-left f-icon-rounded"},[_c('i',{staticClass:"icofont-businesswoman bg-color text-light"}),_c('div',{staticClass:"fb-text"},[_c('h4',[_vm._v("DERECHO EN SEGURIDAD SOCIAL")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow"},[_c('div',{staticClass:"f-box f-icon-left f-icon-rounded"},[_c('i',{staticClass:"icofont-company bg-color text-light"}),_c('div',{staticClass:"fb-text"},[_c('h4',[_vm._v("CORPORATE COMPLIANCE")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow"},[_c('div',{staticClass:"f-box f-icon-left f-icon-rounded"},[_c('i',{staticClass:"icofont-presentation-alt bg-color text-light"}),_c('div',{staticClass:"fb-text"},[_c('h4',[_vm._v("DUE DILLIGENCE")])])])])
}]

export { render, staticRenderFns }