<template>
<header>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="de-flex sm-pt10">
                    <div class="de-flex-col">
                        <!-- logo begin -->
                        <div id="logo">
                            <a @click="toHome()">
                                <img alt="" class="logo" src="images/logos/LOGOBLANCO.svg" width="204px" />
                                <img alt="" class="logo-2" src="images/logos/LOGOAZUL.svg" style="margin-top: -18px" />
                            </a>
                        </div>
                        <!-- logo close -->
                    </div>
                    <div class="de-flex-col header-col-mid">
                        <!-- mainmenu begin -->
                        <ul id="mainmenu">
                            <li><a @click="toHome()">Inicio</a>

                            </li>

                            <li><a>Nosotros</a>
                                <ul>
                                    <li><a @click="toAbout()">Acerca de Aurum</a></li>
                                    <li><a @click="toTeam()">Nuestro equipo</a></li>
                                </ul>
                            </li>
                            <li><a @click="toAreas()">Áreas de práctica</a>
                            </li>
                            <li><a @click="toBlog()">Publicaciones</a>
                            </li>
                            <li><a @click="toContact()">Contacto</a>
                            </li>
                        </ul>
                        <!-- mainmenu close -->
                    </div>
                    <div class="de-flex-col">

                        <span id="menu-btn" style="margin-top: -22px"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
export default {
    name: "Navbar",
    methods: {
        closeNav() {
            jQuery(document).ready(function () {
                var $tmp_h = '90';
                jQuery('header.header-mobile').stop(true).animate({
                    'height': $tmp_h
                }, 200, 'easeOutCubic');
                window.mobile_menu_show = 0;
            })
        },
        toHome() {
            this.closeNav()
            this.$router.push({
                name: 'Home'
            })
        },
        toAbout() {
            this.closeNav()
            this.$router.push({
                name: 'About'
            })
        },
        toTeam() {
            this.closeNav()
            this.$router.push({
                name: 'Team'
            })
        },
        toAreas() {
            this.closeNav()
            this.$router.push({name: 'AreaIntro'})
        },
        toBlog() {
            this.closeNav()
            this.$router.
            push({
                name: 'Blog'
            })
        },
        toContact() {
            this.closeNav()
            this.$router.push({
                name: 'Contact'
            })
        }
    }
}
</script>

<style scoped>
a:hover {
    cursor: pointer !important;
}

li {
    text-transform: uppercase;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {}
 header div#logo img {
        width: 240px;
 }
</style>
