<template>
    <div class="container-fluid">
        <div class="row pt-1 pb-1" style="text-align: center; background-color: #A8A8A8">
            <div class="col-sm-4">
                <a v-if="currentPage > 1" @click="changePage(currentPage-1)" class="btn-smaller text-light bg-color-blue">
                    Anterior
                </a>
            </div>
            <div class="col-sm-4" style="color: black;">
                Página {{currentPage}} de {{numPages}}
            </div>
            <div class="col-sm-4">
                <a v-if="currentPage < numPages" @click="changePage(currentPage+1)" class="btn-smaller text-light bg-color-blue">
                    Siguiente
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <pdf :src="src"
                    :page="currentPage"
                    style="display:inline-block;width:100%"
                ></pdf>
            </div>
        </div>
        <div class="row pt-1 pb-1 mb-1" style="text-align: center; background-color: #A8A8A8">
            <div class="col-sm-4">
                <a  v-if="currentPage > 1" @click="changePage(currentPage-1)" class="btn-smaller text-light bg-color-blue">
                    Anterior
                </a>
            </div>
            <div class="col-sm-4" style="color: black;">
                Página {{currentPage}} de {{numPages}}
            </div>
            <div class="col-sm-4">
                <a v-if="currentPage < numPages" @click="changePage(currentPage+1)" class="btn-smaller text-light bg-color-blue">
                    Siguiente
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import pdf from "vue-pdf"

export default {
    name: "PdfViewer",
    components: { pdf },
    props: {
        pdfSrc: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            src: null,
            numPages: null,
            currentPage: 1,
        }
    },
    methods: {
        changePage(newPage){
            if(newPage <= this.numPages && newPage > 0){
                this.currentPage = newPage
                window.scrollTo(0,0);
            }
        }
    },
    mounted() {
        this.src = pdf.createLoadingTask(this.pdfSrc);
        this.src.promise.then(pdf => {
            this.numPages = pdf.numPages;
        });
        this.$watch( () => this.pdfSrc, () => {
            this.src = pdf.createLoadingTask(this.pdfSrc);
            this.src.promise.then(pdf => {
                this.numPages = pdf.numPages;
                this.currentPage = 1;
            });
        });
    }
}
</script>

<style scoped>
a {
    cursor: pointer;
    user-select: none;
}
</style>